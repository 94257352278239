import React from 'react';
import styled from 'styled-components';
import withThankyouWrapper from '../../components/ThankyouPages/withThankyouWrapper';

const BlueBlock = styled.div`
  width: 100%;
  height: 55px;
  background: #3a576f;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  h2 {
    color: #fff;
    font-size: clamp(18px, 3vw, 26px);
    margin: 0 auto;
  }

  h3 {
    color: #fff;
    font-size: clamp(18px, 3vw, 26px);
    margin: 0 auto 5px;
  }
  h4 {
    color: #fff;
    font-size: clamp(16px, 3vw, 24px);
    margin: 0 auto;
    font-weight: 300;
  }
`;

const TyContainer = styled.div`
  width: 100%;
`;

const HeaderContainer = styled.div`
  padding: 20px 20px 0;
  display: flex;
  align-items: center;
  flex-direction: column;

  h1 {
    font-size: clamp(20px, 3vw, 28px);
    font-weight: 500;
    span {
      font-weight: 800;
    }
  }
`;

const LogoContainer = styled.div`
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  img {
    width: 100%;
  }
`;

const OrderedListContainer = styled.div`
  width: 100%;
  padding: 20px 40px;
  max-width: 800px;
  margin: 0 auto;
`;

const EothlThankyou = () => {
  return (
    <TyContainer>
      <BlueBlock />
      <HeaderContainer>
        <h1>
          We've matched you to <span>OneTrustHomeLoans,</span> top mortgage
          lender with lending options in both Reverse and Traditional Mortgage
        </h1>
        <LogoContainer>
          <img
            src="https://s3-us-west-1.amazonaws.com/offers.printfingertech.net/images/oneTrust.png"
            alt="One Trust"
          />
        </LogoContainer>
      </HeaderContainer>
      <BlueBlock>
        <h2>What Happens Next?</h2>
      </BlueBlock>
      <OrderedListContainer>
        <ol>
          <li>
            A local licensed Mortgage Professional will call/email you within
            the next 24 business hours.
          </li>
          <li>
            Your dedicated Mortgage Professional will discuss and review your
            goals, prepare a proposal with the various solutions that meet you
            needs, and walk you through the process.
          </li>
        </ol>
      </OrderedListContainer>
      <BlueBlock>
        <h2>How do we work?</h2>
      </BlueBlock>
      <OrderedListContainer>
        <ol>
          <li>
            We provide no cost consultation on lending solutions that fit your
            needs and goals.
          </li>
          <li>
            You will have a dedicated licensed Mortgage Professional that will
            work with you every step of the way and ensure all you questions are
            answered.
          </li>
        </ol>
      </OrderedListContainer>
      <BlueBlock>
        <h2>Product Solutions We Service</h2>
      </BlueBlock>
      <OrderedListContainer>
        <ol>
          <li>Home Equity Conversion Mortgage for homeowners 62 and older.</li>
          <li>
            Reverse Mortgage for homeowners age 55 and older with loan amount up
            to $4mm.
          </li>
          <li>
            Traditional Mortgage for homeowners interested in Conventional, FHA,
            VA, USDA, Down Payment Assistance, Jumbo, Non-QM loans and much
            more.
          </li>
        </ol>
      </OrderedListContainer>
    </TyContainer>
  );
};

export default withThankyouWrapper(EothlThankyou);
